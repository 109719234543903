.webdesign {
    padding-top: 45px;
    padding-bottom: 45px;
    &:nth-child(2n) {
        .webdesignRow {
            justify-content: flex-start;
            .webdesignImage {
                left: initial;
                right: 0;
                iframe, img {
                    border-radius: 25px 25px 0 0;
                    @media screen and (min-width: 767px) {
                        border-radius: 35px 0 0 35px;
                    }
                }
            }
            .webdesignText {
                .webdesignTextInner {
                    border-radius: 0 25px 25px 0;
                    @media screen and (min-width: 767px) {
                        border-radius: 0 35px 35px 0;
                    }
                }
            }
        }
    }
    .webdesignImage {
        position: relative;
        left: 0;
        width: 100%;
        margin-bottom: -30px;
        @media screen and (min-width: 767px) {
            top: 100px;
            max-width: 40%;
            position: absolute;
            margin-bottom: 0;
        }
        iframe {
            width: 100%;
            height: 100%;
            min-height: 350px;
            border: 0 none;
            border-radius: 0 25px 25px 0;
            @media screen and (min-width: 767px) {
                min-height: 600px;
                border-radius: 0 35px 35px 0;
            }
        }
        img {
            border-radius: 25px 25px 0 0;
            object-fit: cover;
            max-height: 350px;
            @media screen and (min-width: 767px) {
                border-radius: 0 35px 35px 0;
                max-height: initial;
            }
        }
    }

    .webdesignText {
        position: relative;
        @media screen and (min-width: 767px) {
            max-width: 60%;
        }
        .webdesignTextInner {
            padding: 1rem 2rem;
            border-radius: 25px 0 0 25px;
            @media screen and (min-width: 767px) {
                padding: 30px 100px 50px;
                border-radius: 35px 0 0 35px;
            }
        }
    }
}

.webdesignRow {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    position: relative;
}

.logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 35px 1rem 100px 1rem;
    .logosWrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin: 0 -1rem;
        .logosItem {
            padding: 0 1rem;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            flex: 0 0 50%;
            margin-top: 2rem;
            &:nth-child(-n+2) {
                margin-top: 0;
            }
            @media screen and (min-width: 767px) {
                flex: 1;
                margin-top: 0;
            }
            img {
                max-width: 160px;
            }
        }
    }
}