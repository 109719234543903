.footerWrapper {
    max-width: 1758px;
    margin: 0 auto;
    background-color: #1F1F1F;
    color: #AAAAAA;
    border-radius: 20px 20px 0 0;
    width: calc( 100% - 2rem );
    @media screen and (min-width: 767px) {
        border-radius: 55px 55px 0 0;
    }
    .footerInner {
        padding: 2rem 2rem;
        & > div {
            justify-content: center;
            text-align: center;
            @media screen and (min-width: 767px) {
                justify-content: space-between;
            }
        }
        a {
            color: #FAFAFA;
            font-size: 1rem;
            transition: .2s ease-in-out;
            text-decoration: none;
            &:hover {
                color: #DBAC52;
            }
            @media screen and (min-width: 767px) {
                font-size: 1rem;
            }
        }
    }
}

.footerSocials {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0;
    list-style: none;
    margin: 1rem -8px;
    flex: 0 0 100%;
    justify-content: center;
    @media screen and (min-width: 767px) {
        flex: initial;
        justify-content: initial;
        margin: 0;
    }
    li {
        margin: 0 8px;
        a {
            display: block;
            max-height: 35px;
        }
        svg {
            height: 35px;
            width: 35px;
        }
    }
}

.footerMenuWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .footerMenu {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        padding: 0;
        list-style: none;
        margin: 1rem -10px;
        justify-content: center;
        @media screen and (min-width: 767px) {
            margin: 0;
        }
        li {
            padding: 0 10px;
        }
    }
}

.coffee {
    text-align: center;
    padding: 2rem 0;
    border-top: 1px solid #CDCDCD;
    position: relative;
    @media screen and (min-width: 992px) {
        padding: 100px 0;
    }
}

.footerLine {
    position: absolute;
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: -1;
    max-width: 100%;
}

.footerContact {
    text-align: right;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex: 0 0 100% !important;
    @media screen and (min-width: 767px) {
        flex: 1 !important;
        align-items: flex-end;
    }
}

.footerLogo {
    flex: 0 0 100% !important;
    @media screen and (min-width: 767px) {
        flex: 1 !important;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        & >svg {
            margin-right: 2.5rem;
        }
    }
}