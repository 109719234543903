.googlePartner {
    img {
        max-width: 90px;
        @media screen and (min-width: 767px) {
            max-width: 120px;
        }
        @media screen and (min-width: 992px) {
            max-width: 150px;
        }
    }
}

.blogWrapper {
    @media screen and (min-width: 767px) {
        min-height: 600px !important;
    }
    h1 {
        color: #fafafa;
        position: relative;
        z-index: +1;
    }
    .introInner {
        align-items: flex-end !important;
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        &:before {
            background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));
            height: 80%;
            width: 100%;
            position: absolute;
            content: "";
            display: block;
            left: 0;
            bottom: 0;
        }
    }
    .blogMeta {
        max-width: 700px;
        margin: 0 auto;
        position: relative;
        padding: 0 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        @media screen and (min-width: 767px) {
            justify-content: space-between;
            align-items: flex-end;
            left: -25px;
        }
        & > * {
            flex: 0 0 100%;
            @media screen and (min-width: 500px) {
                flex: 1;
            }
        }
        .blogAuthor {
            display: flex;
            margin: 0 -.5rem;
            align-items: center;
            .blogAuthorImage, .blogAuthorMeta {
                padding: 0 .5rem;
            }
            .blogAuthorImage {
                img {
                    border-radius: 100%;
                    height: 60px;
                    width: 60px;
                }
            }
            .blogAuthorMeta {
                h6 {
                    font-size: 1rem;
                    margin: 0;
                    color: #FAFAFA;
                    line-height: 1rem;
                }
                span {
                    color: rgba(250,250,250,.6);
                    font-size: .825rem;
                }
            }
        }
    }
    .blogSocialShare {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -.2rem;
        justify-content: center;
        .blogSocialItem {
            padding: 0 .2rem;
        }
    }
}

.projectWrapper {
    position: relative;
    .introInner {
        align-items: flex-end !important;
        .introCaption {
            &:before {
                height: 50%;
                width: 100%;
                position: absolute;
                content: "";
                display: block;
                left: 0;
                bottom: 0;
                z-index: -1;
                background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));
            }
        }
    }
}

.introWrapper, .projectWrapper, .blogWrapper {
    position: relative;
    min-height: 100vh;
    padding-top: .01rem;
    overflow: hidden;
    &:before {
        height: 100%;
        width: calc( 100% - 1rem );
        border-radius: 0 55px 55px 0;
        content: "";
        display: none;
        left: 0;
        top: 0;
        position: absolute;
        z-index: -1;
        @media screen and (min-width: 767px) {
            height: calc( 100% - 7rem );
        }
    }
    .blogBackground {
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        z-index: -1;
        height: 100%;
    }
    h1, h2 {
        font-weight: 500;
        margin: 0;
        line-height: 2.5rem;
        @media screen and (min-width: 767px) {
            line-height: 3rem;
        }
        @media screen and (min-width: 992px) {
            line-height: 3.5rem;
        }
        @media screen and (min-width: 1200px) {
            font-size: 5.625rem;
            line-height: 6rem;
        }
    }
    .introActions {
        display: none;
        @media screen and (min-width: 992px) {
            display: flex;
            align-items: center;
            iframe {
                margin-left: 1rem;
            }
        }
    }
    .projectTitle {
        color: #FAFAFA;
        span {
            color: #FAFAFA;
        }
    }
    .projectImage{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -2;
        top: 0;
        margin-left: -15px;
        @media screen and (min-width: 992px) {
            margin-left: -127px;
        }
    }
    .introImage {
        max-width: 100%;
        width: 100%;
        position: absolute;
        bottom: 0;
        width: auto;
        z-index: -1;
        max-height: 70%;
        @media screen and (min-width: 767px) {
            position: absolute;
            right: -3%;
            bottom: 0;
            max-width: 60%;
            object-fit: contain;
            height: 100%;
        }
    }
    .introInner {
        background-color: #EEEEEE;
        width: calc( 100% - 35px );
        height: calc( 100% - 110px );
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        padding: 2rem 1rem;
        border-radius: 0 15px 15px 0;
        overflow: hidden;
        align-items: flex-start;
        @media screen and (min-width: 767px) {
            border-radius: 0 30px 30px 0;
        }
        & > * {
            flex: 0 0 100%;
        }
        @media screen and (min-width: 992px) {
            flex-direction: row;
            border-radius: 0 55px 55px 0;
            padding-left: 127px;
            width: calc( 100% - 50px );
            align-items: center;
        }
    }
}

.review {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    font-size: .875rem;
    flex: 0 0 100%;
    .reviewStars {
        display: flex;
        flex-wrap: wrap;
        margin-right: .5rem;
        @media screen and (min-width: 992px) {
            margin-right: 1.25rem;
        }
        svg {
            margin-right: 3px;
            max-width: 12px;
            @media screen and (min-width: 992px) {
                max-width: initial;
                margin-right: 6px;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}