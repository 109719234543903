.headerLogo {
    position: fixed;
    left: 1rem;
    top: 1rem;
    z-index: 999;
    background-color: #000;
    mix-blend-mode: difference;
    svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
    }
}

.headerButton {
    position: fixed;
    right: 70px;
    top: 5px;
    z-index: 999;
    opacity: 1;
    transition: .2s ease-in-out;
    &.statusHide {
        top: -50px;
        opacity: 0;
    }
}

.burger {
    display: flex;
    outline: none;
    height: 20px;
    width: 30px;
    border: 0px;
    padding: 0px;
    transition: all 250ms ease-out;
    cursor: pointer;
    border: 0 none;
    background: transparent;
    position: fixed;
    top: 20px;
    right: 1rem;
    z-index: 999;
    cursor: pointer;
    mix-blend-mode: difference;
    & > * {
        mix-blend-mode: difference;
    }
    @media screen and (min-width: 992px) {
        top: 1.5rem;
    }
}

.burger:before, .burger:after {
    content: '';
    width: 30px;
    height: 4px;
    position: absolute;
    background: #ffffff;
    transition: all 250ms ease-out;
    will-change: transform;
}

.burger:before {
    transform: translateY(-3px);
}

.burger:after {
    transform: translateY(7px);
}

.active.burger:before {
    transform: translateY(0) rotate(45deg);
}

.active.burger:after {
    transform: translateY(0) rotate(-45deg);
}

.st0{ fill:#FFFFFF; }

.headerStatus {
    position: fixed;
    top: 11px;
    margin: 0 auto;
    left: 0;
    right: 0;
    max-width: 250px;
    font-weight: 500;
    font-size: .75rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 99;
    .headerOnline {
        color: #27AE60;
        display: flex;
        flex-wrap: wrap;    
        justify-content: center;
        align-items: center;
        transition: .2s ease-in-out;
        position: relative;
        top: 0;
        .headerBlob {
            background: #27AE60;
            border-radius: 50%;
            box-shadow: 0 0 0 0 #dbac52;
            transform: scale(1);
            animation: onlinePulse 2s infinite;
            height: 9px;
            width: 9px;
            margin-right: .35rem;
        }
    }
    &.statusHide {
        .headerOnline {
            top: -40px;
        }
    }
}

.menuWrapper {
    background-color: #1F1F1F;
    color: #FAFAFA;
    position: fixed;
    z-index: 99;
    height: 100vh;
    width: 100vw;
    top: 0;
}

@keyframes onlinePulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(39, 174, 96, 0.7);
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(39, 174, 96, 0);
    }
    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(39, 174, 96, 0);
    }
}

.menuProfile {
    font-size: 1rem;
    color: #FAFAFA;
    padding-top: 30px;
    a {
        text-decoration: none;
        color: #FAFAFA;
    }
    img {
        border-radius: 100%;
        max-width: 60px;
        @media screen and (min-width: 992px) {
            max-width: initial;
            max-width: 90px;
        }
    }
    p {
        font-size: .825rem;
        line-height: 1.35rem;
        span {
            color: #dbac52;
            text-transform: uppercase;
        }
    }
}

.menuInner {
    max-width: 1118px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding: 6rem 1rem 1.5rem 1rem;
    overflow-y: auto;
    justify-content: flex-end;
    height: 100%;
    @media screen and (min-width: 992px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
        left: 0;
        right: 0;
    }
}

.menuLines {
    opacity: .8;
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: -1;
}

.mainMenu {
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            line-height: 2.15rem;
            @media screen and (min-width: 767px) {
                line-height: 3.55rem;
                text-align: right;
            }
            a {
                text-decoration: none;
                color: #FAFAFA;
                font-weight: 500;
                transition: .2s ease-in-out;
                font-size: 2rem;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                @media screen and (min-width: 767px) {
                    font-size: 3.25rem;
                }
                &:hover {
                    color: #DBAC52;
                    span {
                        color: #1F1F1F;
                    }
                }
                span {
                    font-size: 12px;
                    background-color: #DBAC52;
                    color: #1F1F1F;
                    border-radius: 12px;
                    line-height: 14px;
                    padding: .1rem .5rem;
                    margin-left: .5rem;
                }
            }
        }
    }
}