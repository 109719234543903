.teamleader {
    svg {
        max-width: 175px;
        @media screen and (min-width: 767px) {
            max-width: 215px;
        }
    }
    p {
        line-height: 1.25rem;
        margin: 0;
        font-size: .875rem;
        margin-top: .5rem;
    }
}