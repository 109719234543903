.cityWrapper {
    position: relative;
    padding: 55px 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    @media screen and (min-width: 767px) {
        padding: 275px 0 265px;
    }
    @media screen and (min-width: 992px) {
        padding: 30px 0 265px;
    }
}

.cityText {
    background-color: #1F1F1F;
    border-radius: 25px 25px 0 0;
    color: #FAFAFA;
    padding: 1.75rem 1.5rem;
    @media screen and (min-width: 767px) {
        max-width: 80%;
        padding: 2.5rem 3rem;
        border-radius: 0 25px 25px 0;
    }
    @media screen and (min-width: 1200px) {
        max-width: 60%;
        padding: 100px 100px 100px 100px;
        border-radius: 0 35px 35px 0;
    }
}

.cityHeadline {
    h2 {
        color: #FAFAFA;
        margin-top: 0;
    }
}

.cityImage {
    width: 100%;
    right: 0;
    overflow: hidden;
    z-index: -1;
    margin-bottom: -50px;
    @media screen and (min-width: 767px) {
        position: absolute;
        margin-top: 0;
        max-width: 60%;
        top: 0;
        right: 0;
    }
    @media screen and (min-width: 992px) {
        position: absolute;
        margin-top: -5rem;
    }
    @media screen and (min-width: 1200px) {
        position: absolute;
        margin-top: 5rem;
        top: 100px;
        margin-bottom: initial;
    }
    img {
        position: relative;
        max-width: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px 20px 0 0;
        max-height: 350px;
        object-fit: cover;
        @media screen and (min-width: 767px) {
            border-radius: 35px 0 0 35px;
            max-height: initial;
        }
    }
}

.cityLogos {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -1rem;
    margin-top: 2rem;
    @media screen and (min-width: 767px) {
        margin-top: 3rem;
    }
    li {
        padding: 0 1rem;
        & > img, & > svg {
            max-width: 100px;
            max-height: 60px;
            height: auto;
            width: 100%;
            display: block;
            @media screen and (min-width: 767px) {
                max-width: 200px;
            }
        }
        .review {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            font-size: .875rem;
            line-height: .875rem;
            .reviewStars {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: .5rem;
                svg {
                    margin-right: 3px;
                    max-width: 12px;
                    @media screen and (min-width: 992px) {
                        max-width: initial;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}