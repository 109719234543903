.testimonial {
    padding: 1rem 0;
    @media screen and (min-width: 767px) {
        padding: 0 0 50px;
    }
}

.testimonialWrapper {
    .testimonialReview {
        background-color: #eeeeee;
        border-radius: 15px;
        padding: 1rem;
        max-width: 964px;
        margin: 0 auto;
        margin-bottom: 2.5rem;
        @media screen and (min-width: 767px) {
            border-radius: 35px;
        }
        @media screen and (min-width: 992px) {
            padding: 2rem;
        }
    }
    .testimonialAuthorWrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media screen and (min-width: 767px) {
            flex-direction: row;
        }
        .testimonialAuthorImage {
            background-color: #eeeeee;
            height: 80px;
            width: 80px;
            border-radius: 100%;
            text-align: center;
            position: relative;
            @media screen and (min-width: 767px) {
                margin-right: 1.5rem;
            }
            @media screen and (min-width: 992px) {
                height: 120px;
                width: 120px;
            }
            &:before {
                background-color: #eeeeee;
                position: absolute;
                height: 35px;
                width: 35px;
                transform: rotate(45deg);
                content: "";
                display: block;
                top: -2.5rem;
                left: 0;
                right: 0;
                margin: 0 auto;
                margin-top: -18px;
            }
            img {
                width: 100%;
                max-width: 100%;
                border-radius: 100%;
                display: block;
            }
        }
        .testimonialPosition, .testimonialCompany {
            color: #333333;
            font-size: .875rem !important;
            line-height: 1.5rem;
        }
        .testimonialPosition {
            @media screen and (min-width: 767px) {
                font-size: .875rem !important;
                line-height: 2rem;
                margin-bottom: -.5rem;
            }
        }
        .testimonialAuthor {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            color: #DBAC52;
            font-weight: 500;
            line-height: 1.5rem;
            font-size: 1.15rem;
            text-align: center;
            margin-top: 1.5rem;
            @media screen and (min-width: 767px) {
                text-align: left;
                margin-top: 0;
                line-height: 1.65rem;
                font-size: 1.45rem;
            }
            span {
                font-size: 1rem;
                font-weight: 200;
                color: #333333;
            }
        }
    }
}