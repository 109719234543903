.stats {
    .statsWrapper {
        background-color: #1F1F1F;
        width: calc( 100% - 2rem );
        margin: 0 auto;
        border-radius: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        max-width: 1582px;
        transform: translateY(50%);
        z-index: 2;
        padding: 1.5rem;
        position: relative;
        @media screen and (min-width: 767px) {
            padding: 3rem 6rem 2rem 6rem;
            border-radius: 20px;
        }
        .statsItem {
            color: #FAFAFA;
            text-align: center;
            font-weight: 500;
            font-size: 1.5rem;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            flex: 1;
            &:nth-child(-n+2) {
                display: none;
            }
            @media screen and (min-width: 992px) {
                font-size: 3rem;
                font-weight: 600;
                &:nth-child(-n+2) {
                    display: flex;
                }
            }
            .statsValue {
                display: inline-block;
                font-size: 50%;
                opacity: .4;
            }
            span {
                margin-top: .25rem;
                font-weight: 500;
                font-size: .65rem;
                color: #DBAC52;
                display: block;
                flex: 0 0 100%;
                @media screen and (min-width: 992px) {
                    font-size: 1.125rem;
                    font-weight: 500;
                }
            }
        }
    }
    .statsInner {
        background-color: #eeeeee;
        border-radius: 15px;
        padding: 2rem;
        z-index: -1;
        position: relative;
        @media screen and (min-width: 767px) {
            border-radius: 30px;
        }
        @media screen and (min-width: 992px) {
            border-radius: 55px;
            padding: 8rem 80px 1.5rem 80px;
        }
    }
    .statsRow {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0 -2rem;
        flex-direction: column;
        @media screen and (min-width: 767px) {
            flex-direction: row;
        }
        .blob {
            background: #dbac52;
            border-radius: 50%;
            margin: 10px;
            height: 30px;
            width: 30px;
            box-shadow: 0 0 0 0 #dbac52;
            transform: scale(1);
            animation: pulse 2s infinite;
            position: absolute;
            bottom: 31%;
            left: 20%;
        }
        .statsText {
            flex: 1;
            padding: 0 2rem;
            padding-top: 2rem;
            @media screen and (min-width: 767px) {
                padding-top: 0;
            }
            a {
                &:first-of-type {
                    font-size: .75rem;
                    @media screen and (min-width: 767px) {
                        font-size: .875rem;
                    }
                }
            }
            p {
                font-size: 1rem;
                line-height: 2rem;
                text-align: left;
            }
            h2 {
                @media screen and (min-width: 767px) {
                    font-size: 2.5rem;
                    line-height: 2.5rem;
                }
                @media screen and (min-width: 1200px) {
                    font-size: 3.438rem;
                    line-height: 4rem;
                }
            }
            .statsHeadline {
                font-size: 1.5rem;
                margin: 0;
            }
        }
        .statsMap {
            position: relative;
            flex: 1;
            padding: 0 1rem;
            svg {
                max-width: 849px;
                width: 100%;
                height: auto;
            }
        }
    }
}

.statsLogo {
    flex: 0 0 239px;
    padding: 0 2rem;
    .statsLogoInner {
        height: 239px;
        width: 239px;
        border-radius: 35px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        img {
            max-width: 170px;
            max-height: 100px;
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(219, 172, 82, 0.7);
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(219, 172, 82, 0);
    }
    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(219, 172, 82, 0);
    }
}