.steps {
    margin-bottom: 2rem;
    @media screen and (min-width: 992px) {
        margin-bottom: 100px;
    }
}

.stepsWrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
    .stepsItem {
        flex: 0 0 100%;
        padding: 0 1rem;
        @media screen and (min-width: 767px) {
            flex: 1;
        }
        h3 {
            color: #DBAC52;
        }
    }
}