.contactCTA {
    position: relative;
    overflow: hidden;
    .contactCTAButton {
        text-decoration: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #DBAC52;
        color: #ffffff;
        padding: 1.5rem 1rem;
        transition: .2s ease-in-out;
        &:hover {
            background-color: #bc9446;
        }
        & > span {
            &:first-child {
                font-weight: 600;
                font-size: 2rem;
                margin-bottom: 1rem;
            }
            &:last-child {
                font-weight: 400;
            }
        }
    }
}