.service {
    padding: 4rem 0 2rem;
    @media screen and (min-width: 992px) {
        padding: 100px 0;
    }
}

.serviceWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -1rem;
    margin-top: 2rem;
    @media screen and (min-width: 992px) {
        margin-top: 4rem;
    }
    .serviceItem {
        flex: 0 0 100%;
        width: 100%;
        padding: 0 1rem;
        margin-bottom: 2rem;
        &:last-child {
            margin-bottom: 0;
        }
        @media screen and (min-width: 767px) {
            margin-bottom: 0;
            flex: 0 0 33.3333%;
            width: 33.3333%;
        }
        .serviceItemInner {
            padding: 1.5rem 2rem 1rem 2rem;
            background-color: #eeeeee;
            border-radius: 35px;
            .serviceItemHeadlineWrapper {
                .serviceItemHeadline {
                    h3 {
                        margin: 0;
                    }
                }
            }
        }
    }
}