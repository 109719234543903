.projectsImages {
    p {
        &:last-child {
            img {
                margin-bottom: 0;
            }
        }
        img {
            border-radius: 15px;
            margin-bottom: 1rem;
            box-shadow: 0 0 6px rgba(0,0,0,.3);
            @media screen and (min-width: 767px) {
                border-radius: 30px;
                margin-bottom: 2rem;
            }
        }
    }
}

.projectsWrapper {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    margin: 0 -1rem;
    padding-top: .5rem;
    padding-bottom: 3rem;
    @media screen and (min-width: 992px) {
        margin: 0 -2rem;
    }
    .projectsItem {
        padding: 0 1rem;
        margin-top: 1rem;
        @media screen and (min-width: 767px) {
            margin-top: 3rem;
            &:nth-child(-n+2) {
                margin-top: 0;
            }
        }
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            .projectsFigure {
                margin-bottom: 0;
            }
        }
        @media screen and (min-width: 776px) {
            flex: 0 0 50%;
            width: 50%;
        }
        @media screen and (min-width: 992px) {
            padding: 0 2rem;
        }
        a {
            display: block;
            width: 100%;
        }
        .projectsFigure {
            position: relative;
            border-radius: 15px;
            overflow: hidden;
            margin: 0;
            box-shadow: 0 0 6px rgba(0,0,0,.3);
            @media screen and (min-width: 767px) {
                margin: 0;
                border-radius: 30px;
            }
            &:hover {
                figcaption {
                    height: 75%;
                    svg {
                        transform: rotate(-45deg);
                    }
                }
            }
            img {
                position: relative;
                object-fit: cover;
                width: 100%;
            }
            .blogCaption {
                h4 {
                    text-transform: none !important;
                }
            }
            figcaption {
                position: absolute;
                bottom: 0;
                width: 100%;
                background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0));
                color: #ffffff;
                transition: .2s ease-in-out;
                padding: 1.5rem;
                display: flex;
                flex-wrap: nowrap;
                flex-direction: row;
                align-items: flex-end;
                justify-content: space-between;
                @media screen and (min-width: 767px) {
                    height: 75%;
                    padding: 2.75rem 1.5rem 1.5rem 1.5rem;
                }
                @media screen and (min-width: 992px) {
                    height: 50%;
                    padding: 2.75rem;
                }
                .projectsCaption {
                    line-height: .75rem;
                }
                .projectsSubTitle {
                    text-transform: uppercase;
                    margin: 0;
                    font-size: .6rem;
                    line-height: 1.4rem;
                    @media screen and (min-width: 767px) {
                        font-size: 1rem;
                        line-height: 1.5rem;
                    }
                    @media screen and (min-width: 992px) {
                        line-height: 1.75rem;
                    }
                }
                svg {
                    transition: .2s ease-in-out;
                    display: none;
                    min-width: 19px;
                    @media screen and (min-width: 480px) {
                        display: block;
                    }
                }
                h4 {
                    margin: 0;
                    font-weight: 600;
                    color: #ffffff;
                    text-transform: uppercase;
                    line-height: 1.15rem;
                    @media screen and (min-width: 480px) {
                        font-size: 1.65rem;
                        line-height: 1.65rem;
                    }
                    @media screen and (min-width: 767px) {
                        font-size: 1.25rem;
                        line-height: 1.25rem;
                    }
                    @media screen and (min-width: 1200px) {
                        line-height: 2.25rem;
                        font-size: 2.25rem;
                    }
                }
            }
        }
    }
}

.projectsImagesWrapper {
    padding: 15px 30px;
    img {
        border-radius: 35px;
    }
}