.welcomeImage {
    max-width: 1582px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    img {
        border-radius: 20px;
        max-width: 100%;
        width: 100%;
        object-fit: cover;
        min-height: 180px;
        @media screen and (min-width: 767px) {
            border-radius: 35px;
        }
    }
}

.welcomeContent {
    background-color: #1F1F1F;
    color: #FAFAFA;
    border-radius: 35px;
    padding: 100px 2rem 1.5rem 2rem;
    position: relative;
    top: -80px;
    margin-bottom: -40px;
    z-index: 1;
    @media screen and (min-width: 767px) {
        padding: 0 2rem 2rem 2rem;
        top: -244px;
        margin-bottom: -200px;
    }
    a, .btn, .btn.link {
        color: #FAFAFA !important;
    }
    .welcomeInner {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        @media screen and (min-width: 776px) {
            padding: 250px 1rem 2rem 1rem;
        }
        @media screen and (min-width: 992px) {
            padding: 300px 5rem 2rem 5rem;
        }
        .welcomeHeadline {
            flex: 0 0 100%;
            @media screen and (min-width: 767px) {
                flex: 0 0 45%;
            }
            h2 {
                color: #FAFAFA;
                margin: 0;
            }
        }
        .welcomeText {
            flex: 0 0 100%;
            @media screen and (min-width: 767px) {
                flex: 0 0 55%;
            }
        }
        .welcomeAction {
            text-align: center;
            @media screen and (min-width: 767px) {
                text-align: left;
            }
        }
    }
}