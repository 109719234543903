.contactCaptionWrapper {
    background-color: #1f1f1f;
    color: #fafafa;
    width: 50%;
    position: relative;
    padding: 100px 150px;
    h1, h2 {
        color: #fafafa;
    }
}

.contact {
    padding: 2rem 0;
    @media screen and (min-width: 992px) {
        padding: 100px 0;
    }
    .contactHeadline {
        padding: 50px 1rem;
        max-width: 1140px;
        margin: 0 auto;
        @media screen and (min-width: 767px) {
            padding: 140px 1rem;
        }
        h1 {
            font-weight: 500;
            margin: 0;
            line-height: 2.5rem;
            @media screen and (min-width: 767px) {
                font-size: 5.625rem;
                line-height: 6rem;
            }
        }
    }
}

.contactContainer {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    .contactSidebar, .contactForm {
        padding: 0 1rem;
    }
    .contactForm {
        flex: 2 1;
        .contactInner {
            background-color: #EEEEEE;
            padding: 2rem 1.5rem;
            background-color: #EEEEEE;
            border-radius: 30px;
            margin-bottom: 2rem;
        }
    }
    .contactSidebar {
        width: 100%;
        padding: 0 1rem;
        @media screen and (min-width: 767px) {
            max-width: 350px;
        }
        @media screen and (min-width: 992px) {
            max-width: 478px;
        }
        .contactSidebarInner {
            background-color: #1f1f1f;
            padding: 1.5rem 2rem;
            color: #FAFAFA;
            bottom: 0;
            flex: 1 1;
            border-radius: 15px;
            margin-top: 1rem;
            box-shadow: 0 0 18px rgba(0,0,0,.4);
            h3 {
                color: #fafafa;
                margin: 0;
            }
            @media screen and (min-width: 767px) {
                padding: 45px 40px 30px 45px;
                margin-top: initial;
                border-radius: 30px;
                box-shadow: none;
                right: 0;
                top: 0;
            }
        }
    }
}

.contactPrivacy {
    font-size: .75rem;
    line-height: 1.25rem;
}

.contactProfile {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -.75rem;
    margin-top: 1.5rem;
    .contactProfileImage, .contactProfileMeta {
        padding: 0 .75rem;
    }
    .contactProfileMeta {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        h4 {
            font-size: 1.25rem;
            line-height: 1.25rem;
            color: #DBAC52;
            margin: 0;
        }
        p {
            margin: 0;
        }
    }
    .contactProfileImage {
        img {
            border-radius: 100%;
            height: 65px;
            width: 65px;
        }
    }
}

.webdesignSection {
    padding: 50px 0 100px;
}

.linkList {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    list-style: none;
    li {
        flex: 1 1 100%;
        @media screen and (min-width: 767px) {
            flex: 1 1 50%;
        }
        @media screen and (min-width: 992px) {
            flex: 1 1 25%;
        }
        a {
            color: #333333;
        }
    }
}