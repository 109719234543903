.pagePosition {
    position: fixed;
    z-index: 999;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    height: 130px;
    width: 2px;
    display: none;
    @media screen and (min-width: 767px) {
        display: block;
    }
    .pageBar {
        background-color: #cdcdcd;
        mix-blend-mode: difference;
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 1;
    }
    .pageProgress {
        background-color: #dbac52;
        height: 0;
        width: 100%;
        z-index: 2;
        mix-blend-mode: initial;
        position: relative;
    }
}