.clients {
    padding: 3rem 0 4rem;
}

.clientsHeadline {
    font-weight: 500;
    text-align: center;
    margin-bottom: 2rem;
    @media screen and (min-width: 767px) {
        margin-bottom: 3rem;
    }
}

.clientsWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    .clientsItem {
        flex: 0 0 50%;
        width: 50%;
        margin-top: 4rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        @media screen and (min-width: 767px) {
            flex: 1 0 33.3333%;
            width: 33.3333%;
        }
        @media screen and (min-width: 992px) {
            flex: 1 0 25%;
            width: 25%;
        }
        @media screen and (min-width: 1200px) {
            flex: 0 0 20%;
            width: 20%;
        }
        &:nth-child(-n+2) {
            margin-top: 0;
        }
        @media screen and (min-width: 767px) {
            &:nth-child(-n+3) {
                margin-top: 0;
            }
        }
        @media screen and (min-width: 992px) {
            &:nth-child(-n+4) {
                margin-top: 0;
            }
        }
        @media screen and (min-width: 1200px) {
            &:nth-child(-n+5) {
                margin-top: 0;
            }
        }
        img {
            max-width: 120px;
            width: 100%;
            max-height: 20px;
            @media screen and (min-width: 767px) {
                max-width: 257px;
                max-height: 50px;
            }
        }
    }
}