.confHeadline {
    padding: 2rem 0;
    max-width: 1140px;
    margin: 0 auto;
    @media screen and (min-width: 767px) {
        padding: 140px 0;
    }
    h1 {
        font-weight: 500;
        margin: 0;
        line-height: 2.5rem;
        @media screen and (min-width: 767px) {
            font-size: 5.625rem;
            line-height: 6rem;
        }
    }
}

.confContainer {
    position: relative;
    z-index: 9;
    padding: 2rem 0 0 0;
}

.confWrapper {
    max-width: 1140px;
    margin: 0 auto;
    padding-bottom: 2rem;
    .confAdditional {
        margin-top: 1.5rem;
    }
    .confList {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        margin: 1rem -1rem 2rem;
        @media screen and (min-width: 767px) {
            margin-bottom: 4rem;
        }
        li {
            flex: 0 0 100%;
            padding: 0 1rem;
            text-align: center;
            @media screen and (min-width: 767px) {
                flex: 1;
                margin-top: 0;
                border-right: 1px solid #dddddd;
                &:last-child {
                    border-right: 0 none;
                }
            }
            margin-top: 1rem;
            span {
                font-weight: 500;
            }
        }
        .confListTitle {
            font-weight: 400;
            line-height: 1.45rem;
            font-size: .75rem;
        }
    }
    .confPrice {
        color: #DBAC52;
        text-align: center;
        font-size: 1.85rem;
        font-weight: 600;
        position: relative;
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        &:after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            width: 80px;
            height: 3px;
            background-color: #DBAC52;
            margin: 0 auto;
            left: 0;
            right: 0;
        }
        .confPriceNotice {
            color: #1f1f1f;
            display: block;
            font-size: .825rem;
            line-height: 1rem;
            text-transform: uppercase;
            font-weight: 400;
            margin-top: .25rem;
            small {
                font-size: .65rem;
            }
        }
    }
    .confResultSummary {
        background-color: #EEEEEE;
        border-radius: 30px;
        padding: 1rem 2rem 1rem 2rem;
        margin-bottom: 2rem;
    }
    .confInnerCenter {
        align-items: center;
    }
    .confInner, .confInnerCenter {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin: 0 -1rem;
        .confForm, .confAdvice {
            padding: 0 1rem;
        }
        .confForm {
            flex: 0 0 100%;
            @media screen and (min-width: 767px) {
                flex: 0 0 65%;
            }
            .confFormInner {
                background-color: #1f1f1f;
                padding: 2rem;
                border-radius: 30px;
                color: #ffffff;
                h3 {
                    color: #ffffff;
                    margin: 0 0 1.5rem;
                }
                p {
                    font-size: .825rem;
                    line-height: .9rem;
                    margin: 1rem 0;
                }
                .confFormInput {
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0 -.5rem;
                    flex-direction: column;
                    @media screen and (min-width: 767px) {
                        flex-direction: row;
                    }
                    .confInput {
                        flex: 1;
                        padding: 0 .5rem;
                        margin-top: 1rem;
                        width: 100%;
                        @media screen and (min-width: 767px) {
                            flex: 0 0 50%;
                            width: 50%;
                            margin-top: 1rem;
                            &:nth-child(-n+2) {
                                margin-top: 0;
                            }
                        }
                        & > div {
                            font-size: .75rem;
                            line-height: .825rem;
                            color: #DBAC52;
                        }
                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        .confAdvice {
            flex: 0 0 100%;
            @media screen and (min-width: 767px) {
                flex: 0 0 35%;
            }
        }
    }
    @media screen and (min-width: 992px) {
        padding-bottom: 100px;
    }
}

.confCalculationBlock {
    bottom: 0;
    width: 100%;
    z-index: 999;
    left: 0;
    @media screen and (min-width: 767px) {
        position: -webkit-sticky;
        position: sticky;
        top: 30px;
    }
    & > a {
        border-radius: 0;
        @media screen and (min-width: 767px) {
            margin-top: 1.5rem;
            border-radius: 35px;
        }
    }
}

.confCalculation {
    width: 100%;
    @media screen and (min-width: 767px) {
        max-width: 350px;
    }
    @media screen and (min-width: 992px) {
        max-width: 478px;
    }
    a {
        border-radius: 0 0 15px 15px;
        @media screen and (min-width: 767px) {
            border-radius: 25px;
        }
    }
    .confCalculationInner {
        background-color: #1f1f1f;
        padding: 1rem;
        color: #FAFAFA;
        bottom: 0;
        flex: 1;
        border-radius: 15px 15px 0 0;
        margin-top: 1rem;
        @media screen and (min-width: 767px) {
            padding: 45px 40px;
            margin-top: initial;
            border-radius: 30px;
            box-shadow: none;
        }
        h3 {
            margin: 0;
            color: #fafafa;
            display: none;
            @media screen and (min-width: 767px) {
                display: block;
            }
        }
        @media screen and (min-width: 767px) {
            right: 0;
            top: 0;
        }
        & > * {
            width: 100%;
        }
        span {
            &:not(.wavy) {
                color: #DBAC52;
                font-weight: 300;
                font-size: .75rem;
                display: block;
            }
        }
    }
}

.confSettings, .confCalculation {
    padding: 0 1rem;
}

.confSettings {
    flex: 2;
    .confImage {
        img {
            width: 100%;
            max-width: 100%;
            display: block;
        }
    }
    .confBlock {
        padding: 2rem 1.5rem;
        background-color: #EEEEEE;
        border-radius: 30px;
        margin-bottom: 2rem;
        text-align: center;
        h1, h2, h3, h4, h5, h6 {
            margin: 0;
            font-weight: 500;
            text-align: center;
        }
        .confUserAddup {
            display: inline-block;
            background-color: #DBAC52;
            color: #ffffff;
            line-height: .825rem;
            font-size: .825rem;
            padding: .25rem .65rem;
            border-radius: 10px;
            margin: 0 auto;
        }
        .confQuestion {
            margin-bottom: 1.25rem;
            h3 {
                font-weight: 500;
            }
            p {
                font-size: .875rem;
                line-height: 1.5rem;
                text-align: center;
            }
        }
        .confUserInput {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 -.5rem;
            .confUserInputItem {
                padding: 0 .5rem;
                flex: 0 0 50%;
                margin-top: 1rem;
                @media screen and (min-width: 767px) {
                    &:nth-child(-n+2) {
                        margin-top: 0;
                    }
                }
                &:nth-child(2n-1) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-end;
                    flex-direction: column;
                    align-items: flex-end;
                }
            }
            a {
                display: block;
                max-width: 202px;
                min-width: 202px;
                text-align: center;
                padding: .1rem 1.35rem;
            }
        }
    }
    .confStep {
        position: relative;
        overflow: hidden;
        &:last-child {
            .confBlock {
                margin-bottom: 0;
            }
        }
    }
}

.confHeader {
    padding: 1rem;
}

.confPriceWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    font-size: .875rem;
    padding-top: .75rem;
    @media screen and (min-width: 767px) {
        padding-top: 1.5rem;
    }
    .confPriceText {
        line-height: 1.2rem;
    }
    .confPriceTax {
        line-height: 1.5rem;
        font-size: .75rem;
    }
}

.confCalculationSupport {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: .5rem;
    @media screen and (min-width: 767px) {
        padding: 20px 0;
    }
    .confCalculationSupportText {
        font-weight: 600;
        font-size: .825rem;
        line-height: 1.25rem;
        .confCalculationSupportNotice {
            font-size: .75rem;
            font-weight: 200;
            opacity: .6;
        }
    }
    .confCalculationSupprtValue {
        font-size: .75rem;
        line-height: 1.45rem;
    }
}

.confLoading {
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.ldsRing {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.ldsRing div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #DBAC52;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #DBAC52 transparent transparent transparent;
}
.ldsRing div:nth-child(1) {
    animation-delay: -0.45s;
}
.ldsRing div:nth-child(2) {
    animation-delay: -0.3s;
}
.ldsRing div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}