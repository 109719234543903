.googleFontsScanner {
    padding: 2rem 0;
    @media screen and (min-width: 992px) {
        padding: 100px 0;
    }
}

.googleFontsForm {
    max-width: 60%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    .googleFontsDomain {
        flex: 1;
        margin-right: 1rem;
    }
}

.googleFontsResult {
    h4 {
        margin: 0;
        margin-bottom: 2rem;
    }
}