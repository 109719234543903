@import-normalize;

@import '../fonts/stylesheet.css';

body {
    background-color: #FAFAFA;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.9rem;
}

* {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
    color: #333333;
    font-weight: 500;
    span {
        color: #DBAC52;
    }
}

h1, h2 {
    @media screen and (min-width: 767px) {
        font-size: 3rem;
        line-height: 3.25rem;
    }
    @media screen and (min-width: 992px) {
        font-size: 3.438rem;
        line-height: 4rem;
    }
}

h4 {
    @media screen and (min-width: 767px) {
        font-size: 2.625rem;
        line-height: 3rem;
    }
}

a {
    color: #DBAC52;
    &:hover {
        text-decoration: none;
    }
}

small {
    font-size: .825rem;
}

label {
    font-size: .875rem;
}

input, textarea {
    padding: 1rem;
    border: 1px solid #eeeeee;
    width: 100%;
    border-radius: 10px;
    font-family: "Poppins", sans-serif;
    @media screen and (min-width: 992px) {
        border-radius: 7px;
    }
    &:focus {
        border: 1px solid #DBAC52;
        outline: none;
    }
}

.img-responsive {
    width: 100%;
    max-width: 100%;
    display: block;
}

.aligncenter {
    text-align: center;
}

.is-invalid-input {
    margin-bottom: .5rem;
}

#projectLink {
    display: none;
    @media screen and (min-width: 767px) {
        display: inline-block;
    }
}

.btn {
    padding: .45rem 1.2rem;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    letter-spacing: -.04rem;
    transition: .2s ease-in-out;
    border: 0 none;
    border-radius: 20px;
    font-size: .875rem;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    @media screen and (min-width: 480px) {
        font-size: 1rem;
    }
    @media screen and (min-width: 767px) {
        font-size: .825rem;
    }
    @media screen and (min-width: 992px) {
        border-radius: 34px;
        font-size: 1rem;
        padding: .55rem 1.35rem;
    }
    &:not(.tel) {
        &:hover {
            svg {
                transform: rotate(-45deg);
            }
        }
    }
    svg {
        margin-left: 1rem;
        transition: .2s ease-in-out;
    }
    &.tel {
        svg {
            margin-left: 0;
            margin-right: 1rem;
        }
    }
    &.link {
        background-color: initial !important;
        color: #DBAC52 !important;
    }
    &.gold {    
        background-color: #DBAC52;
        color: #FAFAFA;
        &:hover {
            background-color: #bc9446;
        }
    }
    &.standard {    
        background-color: #333333;
        color: #FAFAFA;
    }
    &.block {
        display: block;
        text-align: center;
        width: 100%;
    }
    &.outline {
        border: 1px solid #DBAC52;
        color: #1f1f1f;
        &.active, &:hover {
            background-color: #DBAC52;
            color: #FAFAFA;
        }
    }
    &.white {
        color: #ffffff !important;
        padding-top: 0;
        padding-bottom: 0;
        font-weight: 400;
    }
}

.menu {
    padding: 0;
    margin: 0;
    list-style: none;
    &.horizontal {
        display: flex;
        flex-wrap: wrap;
    }
}

@mixin container {
    position: relative;
    max-width: 1758px;
    margin: 0 auto;
    padding: 0 1rem;
    z-index: 1;
    @media screen and (min-width: 992px) {
        padding: 0 2rem;
    }
}

.container {
    @include container;
}

.small-container {
    @include container;
    max-width: 1170px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
    &.justify-between {
        justify-content: space-between;
    }
    &.justify-end {
        justify-content: flex-end;
    }
    &.align-end {
        align-items: flex-end;
    }
    .col {
        flex: 1;
        padding: 0 1rem;
    }
}

#calculation {
    span {
        font-weight: 600;
        color: #FAFAFA;
        display: inline-block;
        font-size: .825rem;
        @media screen and (min-width: 767px) {
            font-size: 1rem;
        }
    }
}


.row-title {
    padding: 1rem 1rem !important;
}

.row-content-text {
    padding: 0 1rem !important;
}

.icon-wrapper {
    margin-top: 12px;
}

#content {
    .btn {
        position: relative;
        overflow: hidden;
        &.animated {    
            &:after {
                background: rgba(255,255,255,.35);
                content: "";
                display: block;
                position: absolute;
                height: 60px;
                width: 300px;
                left: -235px;
                top: -30px;
                transform: rotate(45deg);
                -webkit-animation-name: slideme5;
                animation-name: slideme5;
                -webkit-animation-duration: 3s;
                animation-duration: 3s;
                -webkit-animation-delay: .05s;
                animation-delay: .05s;
                -webkit-animation-timing-function: ease-in-out;
                animation-timing-function: ease-in-out;
                -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
            }
        }
    }
}

.wp-block-image {
    padding: 0;
    margin: 0;
    img {
        max-width: 100%;
        width: 100%;
        height: auto;
        border-radius: 15px;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
        @media screen and (min-width: 767px) {
            border-radius: 30px;
        }
    }
}

.wavy {
    animation-name: wavy;
    animation-duration: .6s;
    animation-timing-function: ease;
    animation-iteration-count: once;
    position: relative;
    top: 0;
    left: 0;
}

@-webkit-keyframes slideme5 {
    0% {
        left: -235px;
        margin-left: 0px;
    }
    30% {
        left: 110%;
        margin-left: 80px;
    }
    100% {
        left: 110%;
        margin-left: 80px;
    }
}

@keyframes slideme5 {
    0% {
        left: -235px;
        margin-left: 0px;
    }
    30% {
        left: 110%;
        margin-left: 80px;
    }
    100% {
        left: 110%;
        margin-left: 80px;
    }
}

@keyframes wavy {
    0% {
        top: 0px;
    }
    50% {
        top: -5px;
    }
    100% {
        top: 0px;
    }
}