.faqWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    @media screen and (min-width: 767px) {
        max-width: 80%;
        margin: 0 auto;
    }
    @media screen and (min-width: 1200px) {
        max-width: 50%;
    }
    .faqItem {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        .faqAnswer {
            display: none;
        }
    }
}

.faqSection {
    padding: 100px 0 50px;
}

.webdesignSection {
    padding: 50px 0 100px;
}

.linkList {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    list-style: none;
    li {
        flex: 1 1 100%;
        @media screen and (min-width: 767px) {
            flex: 1 1 50%;
        }
        @media screen and (min-width: 992px) {
            flex: 1 1 25%;
        }
        a {
            color: #333333;
        }
    }
}